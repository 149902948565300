import React from "react";

import LessonLayout from "~/layouts/lesson";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";
import Button from "~/components/button/button";

const Page = () => {
  return (
      <LessonLayout
        current_step={5}
        lesson={"Lesson 2"}
        color={"ms"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 5</H2>

          <p>Later in this lesson, you will analyze information from two clinical trials. Before doing that, however, it will be helpful to learn more about the purpose of clinical trials and how they are carried out.</p>
          <p>As you read, think about how different aspects of clinical trials relate to your ideas about experimental design.</p>
          <p>Your teacher will tell you which option to choose to learn about clinical trials.</p>

          <div className="flex flex-col lg:flex-row">
            <Button
              action="secondary"
              className="m-2"
              color="purple"
              href="/middle-school/lesson-2/step-5a"
              title="Option 1"
            >
              Option 1
            </Button>
            <Button
              action="secondary"
              className="m-2"
              color="purple"
              href="/middle-school/lesson-2/step-5b"
              title="Option 2"
            >
              Option 2
            </Button>
            <Button
              action="secondary"
              className="m-2"
              color="purple"
              href="/middle-school/lesson-2/step-5c"
              title="Option 3"
            >
              Option 3
            </Button>
          </div>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
